.appointment-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .appointment-form {
    width: 100%;
    max-width: 400px;
  }
  
  .appointment-form label {
    display: block;
  }
  
  .appointment-form input,
  .appointment-form select,
  .appointment-form textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .appointment-form button[type="button"] {
    padding: 10px;
    margin-top: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    cursor: pointer;
    text-align: center;
  }
  
  .appointment-form button[type="button"]:hover {
    background-color: #0069d9;
  }
  